/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import React from 'react';
import { Link, graphql } from 'gatsby';
import '../i18n';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';
import '../css/global.less';
// import twitter from '../img/twitter-square-brands.svg';
// import linkedin from '../img/linkedin-brands.svg';
// import github from '../img/github-square-brands.svg';

// We're using Gutenberg so we need the block styles
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/theme.css';
import Layout from '../components/layout/layout';
import SEO from '../components/SEO';
import Footer from '../components/Footer';

function BlogPostTemplate({ data: { previous, next, post } }) {
  const { t } = useTranslation();

  return (

    // eslint-disable-next-line react/jsx-filename-extension
    <Layout>
      <SEO title={post.title} description={post.excerpt} />
      <Helmet>
        <title>
          {post.title}
          {' '}
        </title>
        <meta name="description" content={t('description')} />
      </Helmet>

      <article className="blog-post" itemScope itemType="http://schema.org/Article">
        <section className="post_header">
          <h1 className="accent-color text-3xl font-bold">{post.title}</h1>

          <p className="center white">{parse(post.excerpt)}</p>

          <a href="#material">
            <div className="button_keep_reading">
              <p>{t('blog.readMore')}</p>
              <FontAwesomeIcon icon={faCircleArrowDown} />
            </div>
          </a>

          <div className="breadcrumble">
            <Link to="/"><p>Home &gt;  </p></Link>
            <Link to="/blog"><p>Blog &gt; </p></Link>
            <Link to={post.uri}><p>{post.title}</p></Link>
          </div>

        </section>

        <section id="post_main" className="post_main">
          <div className="post_content">
            {!!post.content && (
            <section id="material">{parse(post.content)}</section>
            )}
          </div>
          <div className="post_sidebar">
            <div className="post_sidebar_content1">
              <h3 className="blue">{post.date}</h3>
              {post.author && (
                <p className="author_name blue">{post.author.node.name}</p>
              )}
            </div>
            <div className="post_sidebar_content2">
              <h3 className="blue">{t('blog.relatedTopics')}</h3>
              <ul>
                {previous && (
                <li>
                  <Link to={previous.uri} rel="prev">
                    {parse(previous.title)}
                  </Link>
                </li>
                )}

                {next && (
                <li>
                  <Link to={next.uri} rel="next">
                    {parse(next.title)}
                  </Link>
                </li>
                )}
              </ul>
            </div>
          </div>
        </section>

        <Footer />
      </article>
    </Layout>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      author{
        node{
          id
          name
          nickname
        }
      }
      date(formatString: "MMMM DD, YYYY")

      categories {
        nodes {
          name
        }
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }



    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
